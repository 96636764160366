import React, {
    useState,
    useEffect,
    createRef,
    useCallback,
    useMemo,
} from "react";
import {Grid} from "@material-ui/core";
import InvoicesTable from "./InvoicesTable";
import {toast} from "react-toastify";
import Tile from "../../common/Tile";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/People";
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import HistoryIcon from '@material-ui/icons/History';

import {
    getAllInvoices,
    uploadNewInvoice,
    changeInvoiceStage,
    assignInvoiceBudget,
    categoryAssign,
    acceptInvoiceByAccountant,
    deleteInvoice,
    printReport,
    deleteRejectedInvoice,
    downloadInvoiceReportList,
    deleteInvoiceUnassigned,
    uploadNewAttachment,
    downloadInvoicesExcel,
    downloadOutstandingInvoicesExcel,
    geArchivedInvoices, payInvoice, downloadStagesReport, getAllHistoryInvoices, getAllInvoicesFiles, printHistory,
} from "../../../services/invoiceService";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ConfirmInvoiceDialog from "./ConfirmInvoiceDialog";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import {TILES, FILE_TYPE} from "../../common/TileTypes";
import {
    getBudgets,
    getInvoicesForBudget,
    getBudgetDetails,
    getSummary,
} from "../../../services/budgetService";
import TooltipIconButton from "../../common/TooltipIconButton";
import {INVOICE_STAGE} from "../../common/invoiceStages";
import AssignCategoriesDialog from "./AssignCategoriesDialog";
import AssignInvoiceDialog from "./AssignInvoiceDialog";
import AcceptPendingInvoiceDialog from "./AcceptPendingInvoiceDialog";
import {useLocation} from "react-router-dom";
import RejectInvoiceDialog from "./RejectInvoiceDialog";
import ArchiveIcon from "@material-ui/icons/Archive";
import {
    accepting,
    accounting,
    adding,
} from "../../../static/styles/role_themes";
import {parseFloatWithComma} from "../../../utils/customNumberParser";
import moment from "moment";
import GetApp from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import {validateFileExtension} from "../../../utils/FileValidatior";
import CustomLoader from "../../common/CustomLoader";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import debounce from "lodash.debounce";
import UploadAttachmentsDialog from "./UploadAttachmentsDialog";

function Invoices() {
    const INVOICE_TYPE = 'purchase';
    const [budgets, setBudgets] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [invoiceFile, setInvoiceFile] = useState(null);
    const [confirmInvoiceDialog, setConfirmInvoiceDialog] = useState(null);
    const [activeTile, setActiveTile] = useState(TILES.UNASSIGNED);
    const [budgetBasicData, setBudgetBasicData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [uploadAttachmentsDialog, setUploadAttachmentsDialog] = useState(null);
    const [summary, setSummary] = useState({});

    const fileInput = createRef();
    const location = useLocation();

    const getBudgetIDFromPathname = () => {
        const budgetID = parseInt(location.pathname.split("/")[location.pathname.split("/").length - 2]);
        return isNaN(budgetID) ? null : budgetID;
    };
    const getInvoiceTypeFromPathname = () => {
        const invoiceType = location.pathname.split("/").pop();
        return invoiceType;
    };

    const [selectedBudgetID, setSelectedBudgetID] = useState(
        getBudgetIDFromPathname
    );
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(
        getInvoiceTypeFromPathname
    );

    const getAndSetSummary = async () => {
        const summary = await getSummary(INVOICE_TYPE);
        setSummary(summary);
    };

    useEffect(() => {
        getAndSetSummary();
    }, []);

    useEffect(() => {
        const path = location.pathname.split("/").pop();
        if (path === "faktury") {
            setActiveTile(TILES.UNASSIGNED);
        } else {
            setActiveTile(null);
        }
        fetchBudgetDetails();
    }, [selectedBudgetID]);

    useEffect(() => {
        const {pathname} = location;
        setSelectedBudgetID(getBudgetIDFromPathname());
        setSelectedInvoiceType(getInvoiceTypeFromPathname());
    }, [location.pathname]);

    const addThousandSeparator = (rowData, fieldName) => {
        if (rowData[fieldName] === undefined) return "";
        const parsedNumber = rowData[fieldName].split(".");
        return (
            Number(parseFloat(parsedNumber[0]).toFixed(0)).toLocaleString() +
            "." +
            parsedNumber[1]
        );
    };

    const numericalCompare = (a, b, name) => a[name] - b[name];

    const hasAttachments = (rowData) =>
        rowData.attachments && Object.keys(rowData.attachments).length > 0;

    const unassignedColumns = [
        {
            title: "Nr faktury",
            field: "number",
        },
        {
            title: "Wartość",
            field: "price",
            type: "numeric",
            customSort: (a, b) => numericalCompare(a, b, "price"),
        },
        {
            title: "Pozostało",
            field: "left_amount",
            type: "numeric",
            customSort: (a, b) => numericalCompare(a, b, "left_amount"),
        },
        {
            title: "Kontrahent",
            field: "contractor",
        },
        {
            title: "Data dodania",
            field: "add_date",
        },
        {
            title: "Data dokumentu",
            field: "date",
        },
        {
            title: "Data płatności",
            field: "due_date",
        },
        {
            title: "Odrzucona",
            field: "rejected",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>{rowData.rejected ? <CheckIcon/> : null}</Grid>
                </Grid>
            ),
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Przypisz"
                            iconName="eject"
                            onClick={(e) => handleInvoiceAssign(e, rowData)}
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton title="Edytuj" iconName="edit"/>
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Usuń"
                            iconName="delete"
                            onClick={(e) => handleDeleteInvoiceUnassigned(rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    const fileInvoiceColumns = [
        {
            title: "Nazwa",
            field: "name",
        },
        {
            title: "Plik",
            field: "invoice_file",
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Usuń"
                            iconName="delete"
                            onClick={() => handleDeleteInvoice(rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    const rejectedColumns = [
        {
            title: "Nr faktury",
            field: "number",
        },
        {
            title: "Wartość",
            field: "price",
            type: "numeric",
            render: (rowData) => addThousandSeparator(rowData, "price"),
            customSort: (a, b) => numericalCompare(a, b, "price"),
        },
        {
            title: "Kontrahent",
            field: "contractor",
        },
        {
            title: "Data odrzucenia",
            field: "date",
        },
        {
            title: "Data płatności",
            field: "due_date",
        },
        {
            title: "Wykonał akcję",
            field: "rejected",
        },
        {
            title: "Komentarz",
            field: "comment",
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Usuń"
                            iconName="delete"
                            onClick={(e) => handleInvoiceRemove(e, rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    const pendingColumns = [
        {
            title: "Nr faktury",
            field: "number",
        },
        {
            title: "Wartość",
            field: "price",
            type: "numeric",
            render: (rowData) => addThousandSeparator(rowData, "price"),
            customSort: (a, b) => numericalCompare(a, b, "price"),
        },
        {
            title: "Budżet",
            field: "budget_name",
            // render: (rowData) => {
            //     if (budgets) {
            //         const budget = budgets.find((p) => p.id === rowData.budget);
            //         if (budget) {
            //             return budget.name;
            //         }
            //     }
            //     return "";
            // },
        },
        {
            title: "Kontrahent",
            field: "contractor",
        },
        {
            title: "Data dokumentu",
            field: "date",
        },
        {
            title: "Data płatności",
            field: "due_date",
        },
        {
            title: "Załącznik",
            field: "attachments",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        {!hasAttachments(rowData) ? <ClearIcon/> : <CheckIcon/>}
                    </Grid>
                </Grid>
            ),
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Zaakceptuj"
                            iconName="check"
                            onClick={(e) =>
                                handleInvoiceReview(INVOICE_STAGE.ACCEPTED, e, rowData)
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Odrzuć"
                            iconName="close"
                            onClick={(e) =>
                                handleInvoiceReview(INVOICE_STAGE.REJECTED, e, rowData)
                            }
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton title="Edytuj" iconName="edit"/>
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Dodaj załączniki"
                            iconName="upload_file"
                            onClick={() => setUploadAttachmentsDialog(rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    const accoutingColumns = [
        {
            title: "Nr faktury",
            field: "number",
        },
        {
            title: "Wartość",
            field: "price",
            type: "numeric",
            render: (rowData) => addThousandSeparator(rowData, "price"),
            customSort: (a, b) => numericalCompare(a, b, "price"),
        },
        {
            title: "Kontrahent",
            field: "contractor",
        },
        {
            title: "Budżet",
            field: "budget_name",
            // render: (rowData) => {
            //     if (budgets) {
            //         const budget = budgets.find((p) => p.id === rowData.budget);
            //         if (budget) {
            //             return budget.name;
            //         }
            //     }
            //     return "";
            // },
        },
        {
            title: "Kod księgowy",
            field: "code_name",
        },
        {
            title: "Data faktury",
            field: "date",
        },
        {
            title: "Data płatności",
            field: "due_date",
        },
        {
            title: "Załącznik",
            field: "attachments",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        {!hasAttachments(rowData) ? <ClearIcon/> : <CheckIcon/>}
                    </Grid>
                </Grid>
            ),
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Zaakceptuj"
                            iconName="check"
                            onClick={(e) => handleAccountantAccept(rowData.id)}
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Wydruk"
                            iconName="print"
                            onClick={(e) => handleReportPrint(rowData.id, rowData)}
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton title="Edytuj" iconName="edit"/>
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Dodaj załączniki"
                            iconName="upload_file"
                            onClick={() => setUploadAttachmentsDialog(rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    const acceptedColumns = [
        {
            title: "Nr faktury",
            field: "number",
        },
        {
            title: "Wartość",
            field: "price",
            type: "numeric",
            render: (rowData) => addThousandSeparator(rowData, "price"),
            customSort: (a, b) => numericalCompare(a, b, "price"),
        },
        {
            title: "Pozostało",
            field: "left_amount",
            type: "numeric",
            render: (rowData) => addThousandSeparator(rowData, "left_amount"),
            customSort: (a, b) => numericalCompare(a, b, "left_amount"),
        },
        {
            title: "Kontrahent",
            field: "contractor",
        },
        {
            title: "Budżet",
            field: "budget_name",
            // render: (rowData) => {
            //     if (budgets) {
            //         const budget = budgets.find((p) => p.id === rowData.budget);
            //         if (budget) {
            //             return budget.name;
            //         }
            //     }
            //     return "";
            // },
        },
        {
            title: "Data akceptacji",
            field: "modified",
            render: (rowData) => {
                if (rowData && !!rowData["modified"]) {
                    return moment(rowData["modified"]).format("YYYY-MM-DD");
                } else {
                    return "";
                }
            },
        },
        {
            title: "Wykonał akcję",
            field: "confirmed",
        },
        {
            title: "Data dokumentu",
            field: "date",
        },
        {
            title: "Data płatności",
            field: "due_date",
        },
        {
            title: "Załącznik",
            field: "attachments",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        {!hasAttachments(rowData) ? <ClearIcon/> : <CheckIcon/>}
                    </Grid>
                </Grid>
            ),
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Zaakceptuj"
                            iconName="check"
                            onClick={(e) => handleCategoryAssign(e, rowData)}
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton title="Edytuj" iconName="edit"/>
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Usuń"
                            iconName="delete"
                            onClick={(e) => handleDeleteInvoice(rowData)}
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Dodaj załączniki"
                            iconName="upload_file"
                            onClick={() => setUploadAttachmentsDialog(rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    var archiveColumns = [...accoutingColumns];
    archiveColumns.splice(-1, 1);
    archiveColumns = [
        ...archiveColumns,
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Wydruk"
                            iconName="print"
                            onClick={(e) => handleReportPrint(rowData.id, rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        },
    ];

    const historyColumns = [
        {
            title: "Nr faktury",
            field: "number",
        },
        {
            title: "Wartość",
            field: "price",
            type: "numeric",
            render: (rowData) => addThousandSeparator(rowData, "price"),
            customSort: (a, b) => numericalCompare(a, b, "price"),
        },
        {
            title: "Kontrahent",
            field: "contractor",
        },
        {
            title: "Data dokumentu",
            field: "date",
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Wydruk"
                            iconName="print"
                            onClick={(e) => handleHistoryPrint(rowData.id, rowData)}
                        />
                    </Grid>
                </Grid>
            ),
        }
    ];

    const unpaidColumns = [
        {
            title: "Nr faktury",
            field: "number",
        },
        {
            title: "Wartość",
            field: "price",
            type: "numeric",
            render: (rowData) => addThousandSeparator(rowData, "price"),
            customSort: (a, b) => numericalCompare(a, b, "price"),
        },
        {
            title: "Kontrahent",
            field: "contractor",
        },
        {
            title: "Budżet",
            field: "budget_name",
            // render: (rowData) => {
            //     if (budgets) {
            //         const budget = budgets.find((p) => p.id === rowData.budget);
            //         if (budget) {
            //             return budget.name;
            //         }
            //     }
            //     return "";
            // },
        },
        {
            title: "Kod księgowy",
            field: "code_name",
        },
        {
            title: "Data faktury",
            field: "date",
        },
        {
            title: "Data płatności",
            field: "due_date",
        },
        {
            title: "Załącznik",
            field: "attachments",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        {!hasAttachments(rowData) ? <ClearIcon/> : <CheckIcon/>}
                    </Grid>
                </Grid>
            ),
        },
        {
            title: "Akcje",
            field: "id",
            render: (rowData) => (
                <Grid container spacing={2}>
                    <Grid item>
                        <TooltipIconButton
                            title="Zapłacono"
                            iconName="check"
                            onClick={(e) => handleUnpaidAccept(rowData.id)}
                        />
                    </Grid>
                    <Grid item>
                        <TooltipIconButton
                            title="Dodaj załączniki"
                            iconName="upload_file"
                            onClick={() => setUploadAttachmentsDialog(rowData)}
                        />
                    </Grid>
                    {/*<Grid item>*/}
                    {/*  <TooltipIconButton*/}
                    {/*    title="Wydruk"*/}
                    {/*    iconName="print"*/}
                    {/*    onClick={(e) => handleReportPrint(rowData.id, rowData)}*/}
                    {/*  />*/}
                    {/*</Grid>*/}
                    {/*<Grid item>*/}
                    {/*  <TooltipIconButton title="Edytuj" iconName="edit" />*/}
                    {/*</Grid>*/}
                </Grid>
            ),
        },
    ];

    const [chosenColumns, setChosenColumns] = useState(unassignedColumns);
    const [selectedRow, setSelectedRow] = useState({});
    const [openAssignCategoriesDialog, setOpenAssignCategoriesDialog] =
        useState(false);
    const [isOpenInvoiceDiaglog, setOpenInvoiceDialog] = useState(false);
    const [openAssignInvoice, setOpenAssignInvoice] = useState(false);
    const [openAcceptPendingInvoiceDialog, setOpenAcceptPendingInvoiceDialog] =
        useState(false);
    const [pendingRowData, setPendingRowData] = useState(null);

    const [openRejectInvoice, setOpenRejectInvoice] = useState(false);
    const [rejectInvoiceData, setRejectInvoiceData] = useState({});

    const fetchBudgetDetails = async () => {
        try {
            if (!selectedBudgetID) return;
            setIsLoaded(false);
            const response = await getBudgetDetails(selectedBudgetID);
            const fetchedBudgetDetails = response.data;
            setBudgetBasicData(fetchedBudgetDetails.budget);
        } catch (error) {
            console.log(error);
            // toast.error('Coś poszło nie tak.');
        } finally {
            setIsLoaded(true);
        }
    };

    const handleInvoiceReview = async (desiredStage, e, rowData) => {
        switch (desiredStage) {
            case INVOICE_STAGE.ACCEPTED:
                setOpenAcceptPendingInvoiceDialog(true);
                setPendingRowData(rowData);
                break;
            case INVOICE_STAGE.REJECTED:
                setOpenRejectInvoice(true);
                setRejectInvoiceData(rowData);
                break;
            case INVOICE_STAGE.ACCOUNTING:
                await changeInvoiceStage(rowData.id, {
                    stage: INVOICE_STAGE.ACCOUNTING,
                    invoice: rowData.invoice,
                    price: rowData.price,
                }, INVOICE_TYPE);
                break;
            default:
                toast.error("Niewłaściwe przypisanie stanu faktury!");
                break;
        }
        debouncedFetchInvoices();
    };

    const handleAccountantAccept = async (id) => {
        await acceptInvoiceByAccountant(id, INVOICE_TYPE);
        debouncedFetchInvoices();
        fetchBudgets();
    };

    const handleUnpaidAccept = async (id) => {
        await payInvoice(id, INVOICE_TYPE);
        debouncedFetchInvoices();
        fetchBudgets();
    };

    const handleReportPrint = async (id, rowData) => {
        try {
            await printReport(id, INVOICE_TYPE, rowData.budget_name + " " + rowData.code_name);
            toast.success("Drukowanie");
        } catch (e) {
            toast.success("Wystąpił błąd drukowania");
        }
    };

    const handleHistoryPrint = async (id, rowData) => {
        try {
            await printHistory(id, INVOICE_TYPE, rowData.invoice_number);
            toast.success("Drukowanie");
        } catch (e) {
            toast.success("Wystąpił błąd drukowania");
        }
    };

    const handleCategoryAssign = (e, rowData) => {
        setSelectedRow(rowData);
        setOpenAssignCategoriesDialog(true);
    };

    const handleInvoiceAssign = async (e, rowData) => {
        setSelectedRow(rowData);
        setOpenAssignInvoice(true);
    };

    const handleDeleteInvoice = async (rowData) => {
        await deleteInvoice(rowData.id, INVOICE_TYPE);
        debouncedFetchInvoices();
    };

    const handleDeleteInvoiceUnassigned = async (rowData) => {
        await deleteInvoiceUnassigned(rowData.id, INVOICE_TYPE);
        debouncedFetchInvoices();
    };

    const fetchBudgets = async (shouldHideLoader = true) => {
        setIsLoaded(false);
        try {
            const budgets = await getBudgets();
            setBudgets(budgets.data);
        } catch (e) {
            setBudgets([]);
        } finally {
            if (shouldHideLoader) {
                setIsLoaded(true);
            }
        }
    };

    const fetchInvoices = useCallback(async () => {
        let invoices;
        const budgetId = selectedBudgetID;
        const invoiceType = selectedInvoiceType;
        setIsLoaded(false);
        try {
            if (budgetId) {
                invoices = await getInvoicesForBudget(budgetId, invoiceType);
            } else {
                switch (activeTile) {
                    case TILES.ACCOUNTING:
                        invoices = await getAllInvoices(INVOICE_TYPE, TILES.ACCOUNTING);
                        break;
                    case TILES.UNPAID:
                        invoices = await getAllInvoices(INVOICE_TYPE, TILES.UNPAID);
                        break;
                    case TILES.ARCHIVE:
                        invoices = await geArchivedInvoices(INVOICE_TYPE);
                        break;
                    case TILES.HISTORY:
                        setIsLoaded(false);
                        invoices = await getAllHistoryInvoices(INVOICE_TYPE);
                        setIsLoaded(true);
                        break;
                    case TILES.FILE_LIST:
                        invoices = await getAllInvoicesFiles(INVOICE_TYPE);
                        break;
                    default:
                        invoices = await getAllInvoices(INVOICE_TYPE, activeTile);
                        break;
                }
            }
            if (Array.isArray(invoices)) {
                setInvoices(invoices);
            }
        } catch (e) {
            console.error(e);
            setInvoices([]);
        } finally {
            setIsLoaded(true);
        }
    });

    const debouncedFetchInvoices = useMemo(
        () => debounce(fetchInvoices, 300),
        [fetchInvoices]
    );
    const [fileTypeSelected, setFileTypeSelected] = useState(null);

    const fileChange = (event, fileType, rowData) => {
        setInvoiceFile(event.target.files[0]);

        if (rowData) {
            setSelectedRow(rowData);
        }
        if (!!event.target.files[0]) {
            if (fileType) {
                setFileTypeSelected(fileType);
            }
            setConfirmInvoiceDialog(true);
        }
    };

    const uploadFile = async () => {
        try {
            if (!validateFileExtension(invoiceFile.name, "pdf")) {
                toast.error("Dozwolone tylko pliki .pdf!");
                setInvoiceFile(null);
                setConfirmInvoiceDialog(null);
                debouncedFetchInvoices();
                return;
            }
            setIsLoaded(false);
            let fileForm = new FormData();
            fileForm.append("file", invoiceFile);
            fileForm.append("name", invoiceFile.name);
            fileForm.append("type", INVOICE_TYPE);

            if (fileTypeSelected === FILE_TYPE.INVOICE) {
                await uploadNewInvoice(fileForm, INVOICE_TYPE);
            } else if (fileTypeSelected === FILE_TYPE.ATTACHMENT) {
                fileForm.append("invoice_position_budget_id", selectedRow.id);
                await uploadNewAttachment(fileForm);
            } else {
                setInvoiceFile(null);
                setConfirmInvoiceDialog(null);
                toast.error("Możliwe dodanie tylko załącznika lub faktury.");
                debouncedFetchInvoices();
                return;
            }
            setInvoiceFile(null);
            setConfirmInvoiceDialog(null);
            toast.success("Dodano plik.");
            debouncedFetchInvoices();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoaded(true);
        }
    };

    const handleSubmitAssignInvoice = async (invoiceAssignementObject) => {
        try {
            invoiceAssignementObject.invoice = selectedRow.id;
            invoiceAssignementObject.price = parseFloatWithComma(
                invoiceAssignementObject.price
            );

            await assignInvoiceBudget(invoiceAssignementObject, INVOICE_TYPE);
            toast.success("Przypisano.");
            setOpenAssignInvoice(false);
            debouncedFetchInvoices();
        } catch (e) {
            console.error(e);
            toast.error("Nie udało się przypisać.");
        }
    };
    const handleCloseAcceptPending = () => {
        setOpenAcceptPendingInvoiceDialog(false);
        setPendingRowData(null);
        debouncedFetchInvoices();
    };

    const handleInvoiceAcceptanceSubmit = async (submitForm) => {
        try {
            await categoryAssign(submitForm, INVOICE_TYPE);
            toast.success("Powodzenie operacji.");
            setOpenAssignCategoriesDialog(false);
            debouncedFetchInvoices();
        } catch (e) {
            toast.error("Wystąpił błąd przypisania.");
        }
    };

    const handleAssignCategoryDialogClose = () => {
        setOpenAssignCategoriesDialog(false);
        setSelectedRow({});
        debouncedFetchInvoices();
    };

    const handleCloseAssignInvoice = () => {
        setOpenAssignInvoice(false);
    };

    const handleInvoiceRemove = async (e, rowData) => {
        try {
            await deleteRejectedInvoice(rowData.id, 'purchase');
            toast.success("Usunięto.");
        } catch (e) {
            toast.error("Niepowodzenie!");
        } finally {
            debouncedFetchInvoices();
        }
    };

    const handleDownloadInvoiceList = () => {
        downloadInvoiceReportList(
            selectedBudgetID,
            `${budgetBasicData.name} ${budgetBasicData.project}`,
            selectedInvoiceType
        );
    };

    const fetchBudgetAndInvoices = async () => {
        await fetchBudgets(false);
        if (activeTile !== null) {
            debouncedFetchInvoices();
        } else if (selectedBudgetID !== null) {
            fetchInvoices();
        }
    };

    useEffect(() => {
        fetchBudgetAndInvoices();

        switch (activeTile) {
            case TILES.FILE_LIST:
                setChosenColumns(fileInvoiceColumns);
                break;
            case TILES.PENDING:
                setChosenColumns(pendingColumns);
                break;
            case TILES.ACCEPTED:
                setChosenColumns(acceptedColumns);
                break;
            case TILES.REJECTED:
                setChosenColumns(rejectedColumns);
                break;
            case TILES.ACCOUNTING:
                setChosenColumns(accoutingColumns);
                break;
            case TILES.ARCHIVE:
                setChosenColumns(archiveColumns);
                break;
            case TILES.UNASSIGNED:
                setChosenColumns(unassignedColumns);
                break;
            case TILES.HISTORY:
                setChosenColumns(historyColumns);
                break;
            case TILES.UNPAID:
                setChosenColumns(unpaidColumns);
                break;
            default:
                setChosenColumns(unassignedColumns);
                break;
        }
    }, [activeTile]);

    const rejectDialog = () => {
        setOpenRejectInvoice(false);
        debouncedFetchInvoices();
    };

    const closeUploadAttachmentsDialog = (shouldFetch = false) => {
        if (shouldFetch) {
            fetchInvoices();
        }
        setUploadAttachmentsDialog(null);
    };

    return (
        <Grid container direction="column" spacing={2}>
            <CustomLoader loaded={isLoaded}/>
            {selectedBudgetID === null && (
                <Grid container item spacing={2}>
                    <Grid item>
                        <label htmlFor="upload-file">
                            <input
                                ref={fileInput}
                                style={{display: "none"}}
                                id="upload-file"
                                name="upload-file"
                                type="file"
                                onChange={(event) => fileChange(event, FILE_TYPE.INVOICE)}
                            />

                            <Tile
                                style={adding}
                                icon={<PostAddIcon style={{fontSize: "3em"}}/>}
                                text={"Dodaj fakturę"}
                                content={<EmptySummary/>}
                            />
                            <ConfirmInvoiceDialog
                                open={Boolean(confirmInvoiceDialog)}
                                handleClose={setConfirmInvoiceDialog}
                                fileName={invoiceFile || ""}
                                saveDialog={() => uploadFile(FILE_TYPE.INVOICE)}
                            />
                        </label>
                    </Grid>
                    <Grid item>
                        <Tile
                            style={activeTile !== TILES.FILE_LIST ? adding : {}}
                            icon={<DescriptionIcon style={{fontSize: "3em"}}/>}
                            text={"Pliki"}
                            onClick={() => setActiveTile(TILES.FILE_LIST)}
                            isActive={activeTile === TILES.FILE_LIST}
                            content={<EmptySummary/>}
                        />
                    </Grid>
                    <Grid item>
                        <Tile
                            icon={<GetApp style={{fontSize: "3em"}}/>}
                            text={"Pobierz raport należnosci"}
                            onClick={() => downloadOutstandingInvoicesExcel()}
                            content={<EmptySummary/>}
                        />
                    </Grid>
                    <Grid item>
                        <Tile
                            icon={<GetApp style={{fontSize: "3em"}}/>}
                            text={"Pobierz raport etapów"}
                            onClick={() => downloadStagesReport()}
                            content={<EmptySummary/>}
                        />
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.UNASSIGNED ? adding : {}}
                                icon={<AssignmentLateIcon style={{fontSize: "3em"}}/>}
                                text={"Nieprzypisane"}
                                onClick={() => setActiveTile(TILES.UNASSIGNED)}
                                isActive={activeTile === TILES.UNASSIGNED}
                                content={<Summary summary={summary} property="unassigned"/>}
                            />
                        </Grid>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.REJECTED ? adding : {}}
                                icon={<DeleteOutlineIcon style={{fontSize: "3em"}}/>}
                                text={"Odrzucone"}
                                onClick={() => setActiveTile(TILES.REJECTED)}
                                isActive={activeTile === TILES.REJECTED}
                                content={<Summary summary={summary} property="rejected"/>}
                            />
                        </Grid>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.PENDING ? accepting : {}}
                                icon={<AccessAlarmIcon style={{fontSize: "3em"}}/>}
                                text={"Do akceptacji"}
                                onClick={() => setActiveTile(TILES.PENDING)}
                                isActive={activeTile === TILES.PENDING}
                                content={<Summary summary={summary} property="pending"/>}
                            />
                        </Grid>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.ACCEPTED ? accepting : {}}
                                icon={<AssignmentTurnedInIcon style={{fontSize: "3em"}}/>}
                                text={"Zaakceptowane"}
                                onClick={() => setActiveTile(TILES.ACCEPTED)}
                                isActive={activeTile === TILES.ACCEPTED}
                                content={<Summary summary={summary} property="accepted"/>}
                            />
                        </Grid>

                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.ACCOUNTING ? accounting : {}}
                                icon={<PeopleIcon style={{fontSize: "3em"}}/>}
                                text={"Księgowe"}
                                onClick={() => setActiveTile(TILES.ACCOUNTING)}
                                isActive={activeTile === TILES.ACCOUNTING}
                                content={<Summary summary={summary} property="accounting"/>}
                            />
                        </Grid>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.UNPAID ? accounting : {}}
                                icon={<MoneyOffIcon style={{fontSize: "3em"}}/>}
                                text={"Niezapłacone"}
                                onClick={() => setActiveTile(TILES.UNPAID)}
                                isActive={activeTile === TILES.UNPAID}
                                content={<Summary summary={summary} property="unpaid"/>}
                            />
                        </Grid>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.ARCHIVE ? accounting : {}}
                                icon={<ArchiveIcon style={{fontSize: "3em"}}/>}
                                text={"Archiwum"}
                                onClick={() => setActiveTile(TILES.ARCHIVE)}
                                isActive={activeTile === TILES.ARCHIVE}
                                content={<EmptySummary/>}
                            />
                        </Grid>
                        <Grid item>
                            <Tile
                                style={activeTile !== TILES.HISTORY ? accounting : {}}
                                icon={<HistoryIcon style={{fontSize: "3em"}}/>}
                                text={"Historia Dokumentów"}
                                onClick={() => setActiveTile(TILES.HISTORY)}
                                isActive={activeTile === TILES.HISTORY}
                                content={<EmptySummary/>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {selectedBudgetID !== null && (
                <Grid container item spacing={2}>
                    <Grid item>
                        <Tile
                            icon={<GetApp style={{fontSize: "3em"}}/>}
                            text={"Pobierz listę faktur"}
                            onClick={() => handleDownloadInvoiceList()}
                            content={<EmptySummary/>}
                        />
                    </Grid>
                </Grid>
            )}
            {[TILES.ACCEPTED, TILES.PENDING, TILES.REJECTED, TILES.UNASSIGNED, TILES.ACCOUNTING, TILES.ARCHIVE, TILES.UNPAID].some(
                (tile) => tile === activeTile
            ) && (
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => downloadInvoicesExcel('purchase', activeTile)}
                    >
                        Pobierz XLS
                    </Button>
                </Grid>
            )}
            <Grid item>
                <InvoicesTable
                    invoices={invoices}
                    columns={chosenColumns}
                    activeTile={activeTile}
                    fetchInvoices={fetchInvoices}
                    setSelectedRow={setSelectedRow}
                    isOpenInvoiceDiaglog={isOpenInvoiceDiaglog}
                    setOpenInvoiceDialog={setOpenInvoiceDialog}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 15, 20],
                    }}
                />
            </Grid>
            {selectedRow && selectedRow.id && (
                <AssignCategoriesDialog
                    open={openAssignCategoriesDialog}
                    handleClose={handleAssignCategoryDialogClose}
                    handleSubmit={handleInvoiceAcceptanceSubmit}
                    budgetAssignedId={selectedRow && selectedRow.id}
                    invoiceId={selectedRow && selectedRow.id}
                    budgetId={selectedRow && selectedRow.budget}
                    remainingBudget={selectedRow && selectedRow.left_amount}
                    fullPrice={selectedRow && selectedRow.price}
                />
            )}
            {budgets && selectedRow && (
                <AssignInvoiceDialog
                    open={openAssignInvoice}
                    budgets={budgets}
                    invoiceId={selectedRow.id}
                    handleSubmit={handleSubmitAssignInvoice}
                    handleClose={handleCloseAssignInvoice}
                    maxPossiblePrice={selectedRow && selectedRow.price}
                    remainingBudget={selectedRow && selectedRow.left_amount}
                />
            )}
            <AcceptPendingInvoiceDialog
                open={openAcceptPendingInvoiceDialog}
                budgetName={pendingRowData && pendingRowData.budget_name}
                fullPrice={pendingRowData && pendingRowData.price}
                handleClose={handleCloseAcceptPending}
                invoiceId={pendingRowData && pendingRowData.invoice}
                invoicePositionId={pendingRowData && pendingRowData.id}
            />
            {rejectInvoiceData && (
                <RejectInvoiceDialog
                    handleClose={rejectDialog}
                    open={openRejectInvoice}
                    deleteInvoiceData={rejectInvoiceData}
                />
            )}
            {uploadAttachmentsDialog && (
                <UploadAttachmentsDialog
                    handleClose={closeUploadAttachmentsDialog}
                    uploadId={uploadAttachmentsDialog.id}
                />
            )}
        </Grid>
    );
}

const Summary = ({summary, property}) => {
    const items = summary && summary[property] && summary[property].items;
    const value = summary && summary[property] && summary[property].value;
    if (items && value) {
        return (
            <Grid container direction="column">
                <Grid item>{items}</Grid>
                <Grid item>{value} zł</Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container direction="column">
                <Grid item>0</Grid>
                <Grid item>0.00 zł</Grid>
            </Grid>
        );
    }
};

const EmptySummary = () => {
    return <Grid container direction="column" style={{height: 40}}></Grid>;
};

export default Invoices;

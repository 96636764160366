import React, { useState } from "react";
import DefaultTable from "../../common/DefaultTable";
import {CONTRACTS_TILES, TILES} from "../../common/TileTypes";
import FillContractDialog from "./FillContractDialog";

function ContractsTable({
  contracts,
  columns,
  activeTile,
  fetchContracts,
  setSelectedRow,
  isOpenInvoiceDialog,
  setOpenInvoiceDialog,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOrderChange,
  handleSearchChange,
  options,
  components,
}) {
  const [contractData, setContractData] = useState(null);
  const [invid, setInvid] = useState(null);
  const [attachmentId, setAttachmentId] = useState(null);

  const handleClickOpen = async (event, data) => {
    if (isIconClick(event) && !isEditClick(event)) return;
    else if (isUploadAttachmentClick(event)) {
      return;
    }
    await setContractData(data);
    switch (activeTile) {
      case CONTRACTS_TILES.FILE_LIST:
        await setInvid(data.id);
        break;
      default:
        // await setAttachmentId(data.attachment_id);
        await setInvid(data.contract_file);
        break;
    }
    setOpenInvoiceDialog(true);
  };

  const isIconClick = (event) =>
    event.target.className.includes("MuiIcon-root");
  const isEditClick = (event) => event.target.textContent.includes("edit");
  const isUploadAttachmentClick = (event) =>
    event.target.id.includes("upload-attachment");

  const handleClose = (shouldFetch = false) => {
    console.log('close');
    setOpenInvoiceDialog(false);
    setContractData({});
    if (shouldFetch) {
      fetchContracts();
    }
  };

  const handleSave = () => {};

  const invoiceDialogOptions = {
    open: isOpenInvoiceDialog,
    setOpen: setOpenInvoiceDialog,
    handleClose,
    handleSave,
  };

  const isEditable = () => {
    // return (activeTile === CONTRACTS_TILES.FILE_LIST);
    return true
  }


  return (
    <div>
      <DefaultTable
        columns={columns}
        data={contracts}
        onSelectionChange={(rows) => setSelectedRow(rows[0])}
        onRowClick={handleClickOpen}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onOrderChange={handleOrderChange}
        onSearchChange={handleSearchChange}
        options={options}
        components={components}
      />
      <FillContractDialog
          {...invoiceDialogOptions}
          id={invid}
          contractData={contractData}
          isEditable={isEditable()}
          activeTile={activeTile}
        />
    </div>
  );
}

export default ContractsTable;
